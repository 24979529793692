











































































































































import api from "@/api";
import {Component, Vue, Ref} from "vue-property-decorator";
import {
  DataDictionaryDto,
  AuditFlowScope,
  DataDictionaryDtoPagedResultDto,
  HelpAppliesDto
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import {regionData} from "element-china-area-data";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";

@Component({
  components: {SimpleUploadImage, AgileAuditTag, AbSelect, PagedTableView}
})
export default class HelpAppliesList extends Vue {
  @Ref() readonly pagedTableView!: any;
  editId = 0;
  queryForm = {
    name: "",
    phone: "",
    volunteerName:"",
    provinceId: undefined,
    cityId: undefined,
    areaId: undefined
  };
  selectedOptions: string[] = ["", "", ""];
  options: any = regionData;
  visible = false;
  auditStatusList: any[] = [];

  fetchData(params: any) {
    return api.helpApplies.getAll(params);
  }

  async created() {

    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      await api.helpApplies.get({id: this.dataId}).then((res) => {
        this.form = {...res};
        this.selectedOptions = [
          res!.provinceId + "",
          res!.cityId + "",
          res!.areaId + ""
        ];
      });
    }
    this.getUnitList();
  }

  unitList: any = [];
  getUnitList() {
    api.organizationUnit.getAllApproved().then((res) => {
      this.unitList = res;
    });
  }

  // 跳转详情页
  handleDetail(index: number, row: HelpAppliesDto) {
    this.editId = row.id!;
    this.$router.push({
      name: "helpAppliesDetail",
      params: {id: row.id!.toString()}
    });
  }
  handleDistributionOrg( row: HelpAppliesDto) {
    this.$confirm("确定要分配给该机构审核吗?", "提示").then(async () => {
      await api.helpApplies.distributionOrg({ applyId: row.id }).then((res) => {
        this.$message({
          type: "success",
          message: "操作成功!",
        });
        this.fetchData(this.queryForm);
      });
    });
  }

orgId="";
rowId=undefined;

  handleUpdateDistributionOrg() {
    if (this.orgId){
      this.$confirm("确定要分配给该机构审核吗?", "提示").then(async () => {
        await api.helpApplies.distributionAndUpdateOrg({body:{tobeOrganizationUnitId:parseInt(this.orgId),id:this.rowId} }).then((res) => {
          this.visible=false;
          this.$message({
            type: "success",
            message: "操作成功!",
          });

          this.fetchData(this.queryForm);
        });
      });
    }else {
      this.$message({
        type: "error",
        message: "请选择机构!",
      });

    }

  }

  handleShowLog(row: any){
    this.visible=true;
    this.rowId=row.id
  }

  //服务管理
  handleServiceManage(row: any) {
    this.$router.push(
      {
        name: "serviceMaintain-edit",
        params: {
          id: row.id!.toString(),
        },
        query: {
          isHelpApplies: "true"
        }
      }
    );
  }

  // 查看审核记录
  handleAuditFlow(row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.HelpApply,
        hostId: row.id,
        auditFlowId: row.auditFlowId
      }
    });
  }

  handleChange(value: any, label: any) {
    this.queryForm!.provinceId = value[0];
    this.queryForm!.cityId = value[1];
    this.queryForm!.areaId = value[2];
  }

  getProvince(id: any) {
    api.province.get({id: id}).then((res) => {
      this.provinceName = res.provinceName!;
    });
    return this.provinceName;
  }

  getCity(id: any) {
    api.city.get({id: id}).then((res) => {
      this.cityName = res.cityName!;
    });
    return this.cityName;
  }

  getArea(id: any) {
    api.area.get({id: id}).then((res) => {
      this.areaName = res.areaName!;
    });
    return this.areaName;
  }

  handleOnSaved() {
    this.fetchData(this.queryForm);
  }
}
