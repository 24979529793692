var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('paged-table-view',{ref:"pagedTableView",attrs:{"query-form":_vm.queryForm,"search-function":_vm.fetchData},scopedSlots:_vm._u([{key:"condition",fn:function(){return [_c('el-form-item',[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入姓名查询"},model:{value:(_vm.queryForm.name),callback:function ($$v) {_vm.$set(_vm.queryForm, "name", $$v)},expression:"queryForm.name"}})],1),_c('el-form-item',[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入联系电话查询"},model:{value:(_vm.queryForm.phone),callback:function ($$v) {_vm.$set(_vm.queryForm, "phone", $$v)},expression:"queryForm.phone"}})],1),_c('el-form-item',[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入志愿者名称"},model:{value:(_vm.queryForm.volunteerName),callback:function ($$v) {_vm.$set(_vm.queryForm, "volunteerName", $$v)},expression:"queryForm.volunteerName"}})],1),_c('el-form-item',[_c('el-cascader',{attrs:{"options":_vm.options,"placeholder":"请选择地点（省市区）","size":"large","clearable":""},on:{"change":_vm.handleChange},model:{value:(_vm.selectedOptions),callback:function ($$v) {_vm.selectedOptions=$$v},expression:"selectedOptions"}})],1)]},proxy:true},{key:"table",fn:function(){return [_c('el-table-column',{attrs:{"label":"序号","type":"index","width":"50"}}),_c('el-table-column',{attrs:{"label":"求助人","prop":"name","sortable":""}}),_c('el-table-column',{attrs:{"label":"联系电话","prop":"phone","sortable":""}}),_c('el-table-column',{attrs:{"label":"性别","prop":"sex"}}),_c('el-table-column',{attrs:{"label":"出生日期","prop":"birthday","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatYearMonthDayDate")(row.birthday ? row.birthday : "")))])]}}])}),_c('el-table-column',{attrs:{"label":"服务地址","prop":"address"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.province.provinceName || "")+"-"+_vm._s(scope.row.city.cityName || "")+"-"+_vm._s(scope.row.area.areaName || "")+"-"+_vm._s(scope.row.address || "")+" ")]}}])}),_c('el-table-column',{attrs:{"label":"申请人","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.volunteer ? "志愿者" + (row.volunteer.user ? ":" + row.volunteer.user.surname : "") : "站点用户"))])]}}])}),_c('el-table-column',{attrs:{"label":"审核状态","prop":"auditStatus"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('agile-audit-tag',{attrs:{"item":scope.row}})]}}])}),_c('el-table-column',{attrs:{"label":"关联服务数量","prop":"serviceCount"}}),_c('el-table-column',{attrs:{"label":"审核会员单位","prop":"tobeOrganizationUnit.displayName"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row&&scope.row.tobeOrganizationUnit?scope.row.tobeOrganizationUnit.displayName:"")+" "),(scope.row&&scope.row.tobeOrganizationUnit&&scope.row.organizationUnitId===null)?_c('el-tag',[_vm._v("待分配")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"210","class-name":"operate-column"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:('Pages.HelpApply.Search'),expression:"'Pages.HelpApply.Search'"}],attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleDetail(scope.$index, scope.row)}}},[_vm._v("查看 ")]),(scope.row&&scope.row.tobeOrganizationUnit&&scope.row.organizationUnitId===null)?_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:('Pages.HelpApply.Search'),expression:"'Pages.HelpApply.Search'"}],attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleDistributionOrg(scope.row)}}},[_vm._v("分配机构 ")]):_vm._e(),(scope.row&&scope.row.tobeOrganizationUnit&&scope.row.organizationUnitId===null)?_c('el-button',{attrs:{"slot":"reference","size":"mini"},on:{"click":function($event){return _vm.handleShowLog(scope.row)}},slot:"reference"},[_vm._v("修改并分配机构")]):_vm._e(),(
                  scope.row.auditStatus !== null &&
                  scope.row.auditStatus === scope.row.audit
                )?_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:('Pages.HelpApply.ServiceManage'),expression:"'Pages.HelpApply.ServiceManage'"}],attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleServiceManage(scope.row)}}},[_vm._v("服务管理 ")]):_vm._e(),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:('Pages.HelpApply.GetLogs'),expression:"'Pages.HelpApply.GetLogs'"}],attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleAuditFlow(scope.row)}}},[_vm._v("审核记录 ")])]}}])})]},proxy:true}])}),_c('el-dialog',{attrs:{"title":"修改机构","visible":_vm.visible,"close-on-click-modal":false,"width":"500px"},on:{"update:visible":function($event){_vm.visible=$event},"close":function($event){_vm.visible=false}}},[_c('el-select',{model:{value:(_vm.orgId),callback:function ($$v) {_vm.orgId=$$v},expression:"orgId"}},_vm._l((_vm.unitList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.displayName,"value":item.id}})}),1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"default"},on:{"click":function($event){_vm.visible=false}}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleUpdateDistributionOrg}},[_vm._v("确定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }